<template>
  <face-sagging-template
    :face-sagging-locations="faceSaggingLocations"
    :gender="gender"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import FaceSaggingTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-appearance/face-sagging/FaceSaggingTemplate';

import { makeStep } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

const { requiredArrayField, field } = fieldBuilder;

const FIELDS = [requiredArrayField('faceSaggingLocations'), field('gender')];

export default {
  name: 'FaceSagging',
  components: {
    FaceSaggingTemplate
  },
  mixins: [makeStep(FIELDS)]
};
</script>
